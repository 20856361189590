import { Offer, OfferStatus, UpdateOfferPayload } from "../client";
import { EditableOffer } from "../types";
import { ARTICLE_CONTENT_TYPE } from "./articles";
import { getChangedValues } from "./objects";

export const getStatus = (offer: Offer): OfferStatus => {
  if (offer.draft) {
    return OfferStatus.Draft;
  }
  if (offer.expired) {
    return OfferStatus.Expired;
  }
  return OfferStatus.Live;
};

export const getTagColor = (status?: OfferStatus): string => {
  switch (status) {
    case OfferStatus.Live:
      return "success";
    case OfferStatus.Draft:
      return "warning";
    case OfferStatus.Expired:
      return "default";
  }
  return "";
};

export const getProgramList = (offer: Offer): string[] => {
  const allPrograms = offer.targeting_programs.data?.slice() ?? [];
  if (offer.enrollment_program.data) {
    allPrograms.push(offer.enrollment_program.data);
  }
  return allPrograms.map(({ name }) => name).sort((a, b) => a.localeCompare(b));
};

export const cleanOfferForSubmit = (
  originalOffer: Offer,
  editedOffer: Partial<EditableOffer>
): Partial<UpdateOfferPayload> => {
  const {
    // Do not send:
    minutes_to_complete,
    survey_question,
    current_targeting_csv,
    rule_definition,
    // Modify before sending:
    survey,
    card_image,
    success_content_image,
    email_header_image,
    partner_image,
    enrollment_program,
    invite_codes,
    insight_type,
    offer_card_content,
    // Remaining fields to send:
    ...payloadFields
  } = editedOffer;

  const cleanOffer: Partial<UpdateOfferPayload> = {
    ...payloadFields,
    id: originalOffer.id,
    identifier: originalOffer.identifier,
  };

  if (survey?.data) {
    cleanOffer.survey = getChangedValues(
      originalOffer.survey.data,
      survey.data
    );
    if (cleanOffer.survey) {
      cleanOffer.survey.name = originalOffer.survey.data.name;
    }
  }
  if (originalOffer.kind === "one-click") {
    cleanOffer.survey = {
      ...cleanOffer.survey,
      hide_stats: true,
      name: originalOffer.survey.data.name,
      question: null,
    };
  }

  if (
    cleanOffer.content_identifier &&
    !cleanOffer.content_connection_identifier
  ) {
    cleanOffer.content_connection_identifier =
      originalOffer.content_connection_identifier;
  }
  if (
    cleanOffer.content_connection_identifier &&
    !cleanOffer.content_identifier
  ) {
    cleanOffer.content_identifier = originalOffer.content_identifier;
  }

  if (cleanOffer.content_identifier) {
    cleanOffer.content_type = ARTICLE_CONTENT_TYPE;
  }

  /** Relationships */
  if (card_image?.data) {
    cleanOffer.card_image_id = card_image.data.id;
  }
  if (success_content_image?.data) {
    cleanOffer.success_content_image_id = success_content_image.data.id;
  }
  if (email_header_image?.data) {
    cleanOffer.email_header_image_id = email_header_image.data.id;
  }
  if (partner_image?.data) {
    cleanOffer.partner_image_id = partner_image.data.id;
  }
  if (enrollment_program?.data) {
    cleanOffer.enrollment_program_id = enrollment_program.data.id;
  }
  if (invite_codes?.data) {
    cleanOffer.invite_codes = invite_codes.data
      .filter((invite_code) => !!invite_code.code)
      .map((invite_code) => invite_code.code);
  }
  if (insight_type?.data) {
    cleanOffer.insight_type_id = insight_type.data.id;
  }
  if (offer_card_content?.data) {
    cleanOffer.card_content_identifier = offer_card_content.data.identifier;
  }

  return cleanOffer;
};

export const isCmsOfferKind = (offerKind: string) =>
  offerKind.startsWith("cms-");

export const getBaseOfferKind = (offerKind: string) => {
  return offerKind.replace("cms-", "");
};
