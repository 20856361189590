import { CommunicationsConfig } from "../communicationConfigs/types";
import { Image } from "../images";
import { InsightType } from "../insights";
import { Program } from "../programs";
import { Content, PortalPayload, PortalRelationship } from "../types";

export interface Offer {
  application_id?: string;
  auto_push_notification: boolean;
  card_action_deep_link_id: string | null;
  card_action_description: string;
  card_action_url: string;
  card_body: string;
  card_color?: string | null;
  card_header: string;
  card_image: PortalRelationship<Image>;
  card_image_id: number;
  card_image_url: string;
  card_label: string;
  category: string | null;
  compensation_kind: string;
  compensation_label: string;
  completable: boolean;
  complete_on_click: boolean;
  content_connection_identifier: "contentful" | "domino";
  content_identifier: string;
  content_type?: string;
  created_at: string;
  created_by: string;
  current_targeting_csv: PortalRelationship<TargettingCSV>;
  deliverable_via_android: boolean;
  deliverable_via_email: boolean;
  deliverable_via_ios: boolean;
  deliverable_via_web: boolean;
  dismiss_confirmation_message: string;
  dismissible: boolean;
  disqualification_points: number | null;
  draft: boolean; //Required
  draft_rule_definition?: string | null;
  email_body: string;
  email_header_image: PortalRelationship<Image>;
  email_subject: string;
  end_at: string | null;
  enrollment_program: PortalRelationship<Program>;
  expired?: boolean;
  feed_description: string;
  fraudster_threshold: number;
  id: string; //Required
  identifier: string; //Required
  image_filename: string;
  insight_type?: PortalRelationship<InsightType>;
  invite_codes: PortalRelationship<InviteCode[]>;
  kind: string; //Required
  max_android_version: string | null;
  max_ios_version: string | null;
  max_web_version: string | null;
  message_template_id: number;
  message_templates: PortalRelationship<CommunicationsConfig[]>;
  min_android_version: string | null;
  min_ios_version: string | null;
  min_web_version: string | null;
  offer_card_content?: PortalRelationship<Content> | null;
  old_card_image: string;
  old_success_content_image: string;
  partner_image: PortalRelationship<Image>;
  platforms: string;
  points: number;
  rateable: boolean;
  referral_cap: number;
  referral_description: string;
  referral_navigation_label: string;
  referral_share_descriptions: object;
  revenue_generating?: boolean;
  rule_definition?: string | null;
  seconds_to_complete: number;
  shareable: boolean;
  start_at: string | null;
  status?: OfferStatus;
  success_content_heading: string;
  success_content_image: PortalRelationship<Image>;
  success_content_image_url: string;
  success_content_text: string;
  success_navigation_label: string;
  survey: PortalRelationship<OfferSurvey>;
  survey_id: number;
  surveymonkey_survey_id: number;
  survey_question?: PortalRelationship<SurveyQuestion>;
  target_all_users_in_app: boolean;
  targeted: boolean;
  targeting_rule: string;
  targeting_programs: PortalRelationship<Program[]>;
  tier: number;
  type?: string;
  updated_at: string;
  use_insight_image: boolean;
}

export interface OfferKind {
  id: string;
  type: string;
  kind: string;
}

export interface CreateOfferPayload {
  card_action_description?: string;
  card_action_url?: string;
  card_body?: string;
  card_header?: string;
  compensation_kind?: string;
  compensation_label?: string;
  complete_on_click?: boolean;
  email_body?: string;
  email_header_image_path?: Image;
  email_subject?: string;
  feed_description?: string;
  identifier: string;
  image_filename?: string;
  kind: string;
  points?: number;
  seconds_to_complete?: number;
  success_content_heading?: string;
  success_content_image_id?: number;
  success_content_text?: string;
  success_navigation_label?: string;
  survey?: OfferSurvey;
  targeted?: boolean;
  targeting_rule?: string;
  tier?: number;
}

export interface UpdateOfferPayload
  extends Omit<Offer, "survey" | "insight_type" | "invite_codes"> {
  card_content_identifier: string | null;
  file?: BinaryType;
  success_content_image_id?: number;
  email_header_image_id?: number;
  partner_image_id?: number;
  enrollment_program_id?: string;
  survey?: Partial<OfferSurvey>;
  invite_codes?: string[];
  insight_type_id?: string;
}

export interface CloneOfferPayload {
  new_identifier: string;
}

export enum OfferStatus {
  Draft = "draft",
  Live = "live",
  Expired = "expired",
}

export type OfferSurvey = {
  created_at: string;
  hide_stats: boolean;
  id: number;
  identifier: string;
  instructions: string;
  kind: string;
  label: string;
  label_id: number;
  name: string;
  question: string | null;
  questions: string[];
  single_tap_answers: string[];
  updated_at: string;
};

export type ProfileMapping = {
  created_at: string;
  id: string;
  identifier: ProfileMappingIdentifier;
  response_schema: any;
  type: "profile_mapping";
  updated_at: string;
};

export type ProfileMappingIdentifier =
  | "weight"
  | "sex"
  | "postal_code"
  | "phone_number"
  | "date_of_birth";

export interface SurveyQuestion {
  configuration: SurveyQuestionConfiguration;
  id: number;
  question_type: SurveyQuestionType | ProfileMappingIdentifier;
  response_schema: any;
  profile_mapping?: PortalRelationship<ProfileMapping>;
}

export type SurveyQuestionType =
  | "one-click"
  | "checkbox"
  | "radio"
  | "program-enrollment"
  | "text-field"
  | "numerical"
  | "date-picker";

export type SurveyQuestionConfiguration =
  | CheckboxQuestionConfiguration
  | OneClickQuestionConfiguration
  | RadioQuestionConfiguration
  | ProgramEnrollmentQuestionConfiguration
  | TextFieldQuestionConfiguration
  | BaseQuestionConfiguration;

export interface BaseQuestionConfiguration {
  question: string;
}

export interface TextFieldQuestionConfiguration
  extends BaseQuestionConfiguration {
  min_length?: number;
  max_length?: number;
  pattern?: string;
  input_type?: "default" | "number-pad";
}

export interface OneClickQuestionConfiguration
  extends BaseQuestionConfiguration {
  options: OneClickSurveyOption[];
}

export interface OneClickSurveyOption {
  label: string;
  value: string;
}

export interface ProgramEnrollmentQuestionConfiguration
  extends BaseQuestionConfiguration {
  options: ProgramEnrollmentSurveyOption[];
}

export interface ProgramEnrollmentSurveyOption {
  label: string;
  value: string;
}

export interface CheckboxQuestionConfiguration
  extends BaseQuestionConfiguration {
  options: CheckboxSurveyOption[];
}

export interface CheckboxSurveyOption {
  exclusive: boolean;
  label: string;
  value: string;
}

export interface RadioQuestionConfiguration extends BaseQuestionConfiguration {
  options: RadioSurveyOption[];
}

export interface RadioSurveyOption {
  label: string;
  value: string;
}

export interface OfferStatsParams {
  start_date: Date;
  end_date: Date;
}

export type OfferStats =
  | OfferInteractionStats
  | OfferImpressionStats
  | OfferShareClickStats;

export interface OfferStatsCollection {
  start: string;
  end: string;
  rows: OfferStats[];
}
export interface OfferInteractionStats {
  clicks: number;
  completions: number;
  date: string;
  dismissals: number;
  disqualifications: number;
  shares: number;
}
export interface OfferImpressionStats {
  date: string;
  impressions: number;
}

export interface OfferShareClickStats {
  date: string;
  share_clicks: number;
}

export interface TargettingCSV {
  id: string;
  url: string;
  user_count: number;
}

interface InviteCode {
  code: string;
  id: string;
  type: "invite_code";
}

export interface OfferLabel {
  created_at: string;
  id: number;
  label: string;
  label_behavior_id: number;
  question_id: number;
  relation_id: number;
  survey_id: number;
  system_generated: boolean;
  updated_at: string;
}

/**
 * Payload types for POST/PUT to portal request in json:api schema.
 */
export type UpdateOffer = PortalPayload<"offer", UpdateOfferPayload>;
export type CreateSurveyQuestion = PortalPayload<
  "survey_question",
  Omit<SurveyQuestion, "id">
>;
export type UpdateSurveyQuestion = PortalPayload<
  "survey_question",
  Partial<SurveyQuestion>
>;

export type ComputeReachPayload = {
  targeting_rule?: string;
  synapse_reach: boolean;
};

export type ComputeReachResult = {
  targeted_user_count: string;
  targeting_rule_reach_draft_count: number;
};

export interface AdvancedSurvey {
  description: string;
  id: string;
  name: string;
  type: string;
}
