import { notification, Space, Switch, Typography } from "antd";
import React from "react";

import Notifications from "../assets/data/notifications";
import {
  selectUnarchivedWorkspaceLocales,
  usePublishWorkspaceLocale,
  useUnpublishWorkspaceLocale,
  useWorkspaceLocales,
} from "../client";
import Modal, { ModalProps } from "./Modal";

const LaunchWorkspaceLocalesModal: React.FunctionComponent<ModalProps> = (
  modalProps
) => {
  const workspaceLocales = useWorkspaceLocales({
    select: selectUnarchivedWorkspaceLocales,
  });
  const publishWorkspaceLocale = usePublishWorkspaceLocale({
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.WorkspaceLocalePublish.success,
      });
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description: `${Notifications.WorkspaceLocalePublish.error} ${error.errors?.[0]?.detail}`,
      });
    },
  });
  const unpublishWorkspaceLocale = useUnpublishWorkspaceLocale({
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.WorkspaceLocaleUnpublish.success,
      });
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description: `${Notifications.WorkspaceLocaleUnpublish.error} ${error.errors?.[0]?.detail}`,
      });
    },
  });

  const handleWorkspaceLocaleSwitch = React.useCallback(
    (workspaceLocaleId: string, checked: boolean) => {
      if (checked) {
        publishWorkspaceLocale.mutate({ id: workspaceLocaleId });
      } else {
        unpublishWorkspaceLocale.mutate({ id: workspaceLocaleId });
      }
    },
    [publishWorkspaceLocale, unpublishWorkspaceLocale]
  );

  return (
    <Modal
      cancelText="Cancel"
      okButtonProps={{ style: { display: "none" } }}
      title="Launch to Participants"
      {...modalProps}
    >
      <Typography.Paragraph>
        Please choose language to launch. Disabling a language will remove it
        from the participant experience.
      </Typography.Paragraph>
      <Space direction="vertical" size="large">
        {workspaceLocales.data?.map((workspaceLocale) => (
          <Space direction="horizontal">
            <Switch
              checked={workspaceLocale.state === "published"}
              loading={
                workspaceLocales.isLoading ||
                publishWorkspaceLocale.isLoading ||
                unpublishWorkspaceLocale.isLoading
              }
              onChange={(checked) => {
                handleWorkspaceLocaleSwitch(workspaceLocale.id, checked);
              }}
            />
            <Typography.Text>
              {workspaceLocale.locale?.data.display_name}
            </Typography.Text>
          </Space>
        ))}
      </Space>
    </Modal>
  );
};
export default LaunchWorkspaceLocalesModal;
