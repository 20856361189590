import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ARTICLE_CONTENT_TYPE } from "../../../utils/articles";
import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { Content, JsonApiResponse } from "../types";

const useArticles = <TData extends {} = JsonApiResponse<Content[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Content[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Content[]>, PortalClientError, TData>(
    ["contents"],
    () => client.get(`/contents?content_type=${ARTICLE_CONTENT_TYPE}`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};
export default useArticles;
