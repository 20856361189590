import { Button, PageHeader, Space, Tabs } from "antd";
import React from "react";
import { NavLink, RouteChildrenProps, useHistory } from "react-router-dom";

import { useExperimentByKey } from "../client";
import { Feature, Home, Internationalization } from "../components/Workspace";
import LaunchWorkspaceLocalesModal from "../modals/LaunchWorkspaceLocalesModal";

const pageTitle = "Workspace Settings";

const pathsToTabs: { [key: string]: string } = {
  "/": "general",
  "/workspace_settings/features": "features",
  "/workspace_settings/internationalization": "internationalization",
};

const workspaceTabs = [
  {
    key: "general",
    label: "General",
    children: <Home />,
  },
  {
    key: "features",
    label: "Feature Flags",
    children: <Feature />,
  },
];

const WorkspaceSettingsView: React.FunctionComponent<RouteChildrenProps> = ({
  match,
}) => {
  const internationalizationExperiment = useExperimentByKey({
    key: "internationalization_settings_enabled",
  });
  const activeTab = pathsToTabs[match!.path] ?? "general";
  const history = useHistory();

  const handleTabChange = (activeKey: string) => {
    switch (activeKey) {
      case "general":
        history.push("/workspace_settings/");
        break;
      default:
        history.push(`/workspace_settings/${activeKey}`);
        break;
    }
  };

  const tabsWithInternationalization = React.useMemo(() => {
    return internationalizationExperiment.experiment?.active
      ? [
          ...workspaceTabs,
          {
            key: "internationalization",
            label: "Internationalization",
            children: <Internationalization />,
          },
        ]
      : workspaceTabs;
  }, [internationalizationExperiment.experiment?.active]);

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={{
          itemRender: (route, params) => (
            <NavLink to={route.path} {...params}>
              {route.breadcrumbName}
            </NavLink>
          ),
          routes: [
            {
              path: "/",
              breadcrumbName: "Home",
            },
            {
              path: match!.path,
              breadcrumbName: pageTitle,
            },
          ],
        }}
        extra={
          activeTab === "internationalization" && (
            <LaunchWorkspaceLocalesModal
              renderButton={(open) => (
                <Button onClick={open} type="primary">
                  Launch
                </Button>
              )}
            />
          )
        }
        style={{ backgroundColor: "#fff" }}
        title={pageTitle}
      />
      <Space
        direction="vertical"
        size={16}
        style={{ width: "100%", padding: "24px" }}
      >
        <Tabs
          activeKey={activeTab}
          items={tabsWithInternationalization}
          onChange={handleTabChange}
        />
      </Space>
    </React.Fragment>
  );
};
export default WorkspaceSettingsView;
