import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { useClientContext } from "../../ClientContext";
import config from "../../config";
import { createContentfulClient } from "../../contentful";

export const contentfulQueryKeys = {
  types: ["contentful_types"],
  type: ["contentful_type"],
  entries: ["contentful_entries"],
  entry: ["contentful_entry"],
  entrySnapshots: ["contentful_entry_snapshots"],
  locales: ["contentful_locales"],
};

export const useContentfulClient = () => {
  const { getAccessToken, environment } = useClientContext();
  const client = createContentfulClient(
    getAccessToken,
    environment as keyof typeof config
  );
  return client;
};

export const useContentfulTypes = () => {
  const client = useContentfulClient();
  return useQuery(contentfulQueryKeys.types, () =>
    client.contentType.getMany({})
  );
};

export const useContentfulType = ({ entryType }: { entryType: string }) => {
  const client = useContentfulClient();
  return useQuery([...contentfulQueryKeys.type, entryType], () =>
    client.contentType.get({ contentTypeId: entryType })
  );
};

export const useContentfulEntries = ({ type = "page" }: { type: string }) => {
  const client = useContentfulClient();
  return useQuery(contentfulQueryKeys.entries, () =>
    client.entry.getMany({ query: { content_type: type } })
  );
};

export const useContentfulEntryById = (
  { id }: { id: string },
  queryOptions?: any
) => {
  const client = useContentfulClient();
  return useQuery(
    [...contentfulQueryKeys.entry, id],
    () => client.entry.get({ entryId: id }),
    queryOptions
  );
};

export const useContentfulEntrySnapshots = ({ id }: { id: string }) => {
  const client = useContentfulClient();
  return useQuery([...contentfulQueryKeys.entrySnapshots, id], () =>
    client.snapshot.getManyForEntry({ entryId: id })
  );
};

export const useContentfulLocales = () => {
  const client = useContentfulClient();
  const queryProps = useQuery(contentfulQueryKeys.locales, () =>
    client.locale.getMany({})
  );
  const localeOptions = useMemo(
    () =>
      queryProps.data
        ? queryProps.data.items?.map((locale) => ({
            label: locale.name,
            value: locale.code,
          }))
        : [],
    [queryProps.data]
  );

  const defaultLocale = useMemo(() => {
    if (queryProps.data) {
      const locale = queryProps.data.items.find((l) => l.default);
      return locale?.code;
    }
    return undefined;
  }, [queryProps.data]);
  return { ...queryProps, localeOptions, defaultLocale };
};
