import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import type { PortalClientError } from "../..";
import { useClient } from "../../ClientContext";
import type { Content, JsonApiResponse } from "../types";
import { ArticlePayload } from "./types";

const useCreateArticle = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Content>,
    PortalClientError,
    { data: ArticlePayload }
  >
) => {
  const client = useClient();

  return useMutation(
    async (data) => {
      return await client.post("/contents", data);
    },
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useCreateArticle;
