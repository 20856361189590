import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import type { PortalClientError } from "../..";
import { useClient, useQueryClient } from "../../ClientContext";
import type { JsonApiResponse } from "../types";
import type { Brand, UpdateBrandPayload } from "./types";

const useUpdateBrand = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Brand>,
    PortalClientError,
    UpdateBrandPayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (brand) => {
      const data = { brand };
      return await client.put("/brand", data);
    },
    {
      ...queryOptions,
      onSuccess: (brand) => {
        queryClient.setQueryData(["brands"], brand);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess(brand, brand.data, brand);
        }
      },
    }
  );
};

export default useUpdateBrand;
