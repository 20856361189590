import type { FieldValues } from "react-hook-form";

import { useExperimentByKey } from "../../client";
import { useIsDefaultLocale } from "../LocaleContext";
import FormItem from "./FormItem";
import { LocalizedFormItemProps } from "./types";

const LocalizedFormItem = <TFieldValues extends FieldValues = FieldValues>({
  localizationDisabled,
  help,
  ...props
}: LocalizedFormItemProps<TFieldValues>) => {
  const internationalizationExperiment = useExperimentByKey({
    key: "internationalization_settings_enabled",
  });
  const isDefaultLocale = useIsDefaultLocale();
  const isInternationalizationEnabled =
    internationalizationExperiment?.experiment?.active ?? false;
  const isLocalizationDisabled =
    localizationDisabled && isInternationalizationEnabled && !isDefaultLocale;

  return (
    <FormItem
      help={localizationDisabled ? "Localization disabled" : help}
      readOnly={isLocalizationDisabled}
      {...props}
    />
  );
};
export default LocalizedFormItem;
