import { Model, PortalPayload } from "../types";
export const ArticleStatus = {
  Draft: "draft",
  Published: "published",
  PublishedChanges: "Published & Changes",
  Unpublished: "Unpublished",
  Expired: "expired",
};

interface ArticleRequestAttributes {
  content_connection_identifier: string;
  content_identifier: string;
  content_name: string;
  content_type: string;
  content_revision?: string;
  publish?: boolean;
}

interface ArticleRequestData {
  attributes: ArticleRequestAttributes;
}

export type ArticlePayload = PortalPayload<ArticleRequestData>;

export interface ArticleResponseAttibutes extends Model<string> {
  active: boolean;
  content_connection_identifier: string;
  created_at: string;
  content_type: string;
  identifier: string;
  updated_at: string;
}
