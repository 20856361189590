import { JsonApiResponse } from "../types";
import { WorkspaceLocale } from "./types";

/**
 * Selects workspace locales that are not in an archived state
 */
export const selectUnarchivedWorkspaceLocales = (
  response: JsonApiResponse<WorkspaceLocale[]>
): WorkspaceLocale[] =>
  response.data.filter(
    (workspaceLocale) => workspaceLocale.state !== "archived"
  );
