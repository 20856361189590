import { createClient, EntryProps } from "contentful-management";
import _ from "lodash";

import config from "../config";
import {
  UpsertContentfulEntryProps,
  PublishContentfulEntryProps,
} from "./types";

export const createContentfulClient = (
  getPortalAccessToken: () => string,
  environment: keyof typeof config
) =>
  createClient(
    {
      accessToken: "REPLACED_BY_PORTAL",
      baseURL: `${config[environment].domain}/v1/contentful_proxy/management`,
      headers: {
        Authorization: `Bearer ${getPortalAccessToken()}`,
      },
      retryOnError: false,
    },
    {
      type: "plain",
      defaults: {
        spaceId: "s6qlqtr1lb5z",
        environmentId: "master",
      },
    }
  );

const arrayReplacementHandler = (
  objValue: Record<string, any>,
  srcValue: Record<string, any>
) => (Array.isArray(objValue) ? srcValue : undefined);

export const getValuesFromContentfulEntryByLocale = (
  entry: EntryProps<Record<string, any>>,
  locale: string
) =>
  Object.keys(entry.fields).reduce((acc, curr: string) => {
    acc[curr] = entry.fields[curr][locale];
    return acc;
  }, {} as any);

export const mergeToContentfulFormat = (
  value: Record<string, any>,
  locale: string,
  nonLocalizableFields?: string[],
  defaultLocale?: string
) => {
  const fields = Object.keys(value).reduce((acc, curr: string) => {
    const isNonLocalizable = nonLocalizableFields?.includes(curr);
    acc[curr] =
      isNonLocalizable && defaultLocale && defaultLocale !== locale
        ? {
            [locale]: value[curr],
            [defaultLocale]: value[curr],
          }
        : {
            [locale]: value[curr],
          };
    return acc;
  }, {} as any);

  return {
    fields,
  };
};

export const publishContentfulEntry = async ({
  entry,
  client,
}: PublishContentfulEntryProps) => {
  try {
    const published = await client.entry.publish(
      {
        entryId: entry.sys.id,
      },
      entry
    );
    return published;
  } catch (err: any) {
    const errJson = JSON.parse(err.message ?? "{}");
    console.error("Contentful Error: ", errJson);
    throw errJson;
  }
};

export const upsertContentfulEntry = async ({
  values,
  nonLocalizableFields,
  defaultLocale,
  locale,
  existingEntry,
  client,
  entryType,
}: UpsertContentfulEntryProps) => {
  const { fields } = mergeToContentfulFormat(
    values,
    locale,
    nonLocalizableFields,
    defaultLocale
  );

  if (!existingEntry?.sys?.id) {
    try {
      const entry = await client.entry.create(
        { contentTypeId: entryType },
        {
          fields,
        }
      );

      return entry;
    } catch (err: any) {
      const errJson = JSON.parse(err.message ?? "{}");
      console.error("Contentful Error: ", errJson);
      throw errJson;
    }
  } else {
    try {
      const entry = await client.entry.update(
        { entryId: existingEntry.sys.id },
        {
          fields: _.mergeWith(
            {},
            existingEntry.fields,
            fields,
            arrayReplacementHandler
          ),
          sys: existingEntry.sys,
        }
      );
      return entry;
    } catch (err: any) {
      const errJson = JSON.parse(err.message ?? "{}");
      console.error("Contentful Error: ", errJson);
      throw errJson;
    }
  }
};
