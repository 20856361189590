import { Form as AntdForm } from "antd";
import { Children, cloneElement, isValidElement, useEffect } from "react";
import type { FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormItemProps } from "./types";

const FormItem = <TFieldValues extends FieldValues = FieldValues>({
  children,
  control,
  name,
  disabled,
  readOnly,
  help,
  valuePropName,
  overrideFieldOnChange,
  ...props
}: FormItemProps<TFieldValues>) => {
  const { field, fieldState } = useController({
    name,
    control,
    disabled: disabled,
  });
  const form = AntdForm.useFormInstance();

  useEffect(() => {
    form.setFieldValue(name, field.value);
    //eslint-disable-next-line
  }, [field.value]);

  return (
    <AntdForm.Item
      help={fieldState.error?.message ?? help ?? undefined}
      initialValue={field.value}
      name={name as any}
      validateStatus={fieldState.invalid ? "error" : undefined}
      {...props}
    >
      <div>
        {Children.map(
          children,
          (child) =>
            isValidElement(child) &&
            cloneElement(child, {
              ...field,
              //@ts-expect-error TODO: Figure out issues with types here
              id: field.name,
              readOnly: readOnly,
              disabled: disabled,
              //@ts-expect-error onChange type safe is not necessary here
              onChange: (...params) => {
                if (readOnly) {
                  return;
                }
                child.props.onChange && child.props.onChange(...params);
                overrideFieldOnChange
                  ? overrideFieldOnChange(...params)
                  : field.onChange(...params);
              },
              onBlur: () => {
                child.props.onBlur && child.props.onBlur();
                field.onBlur();
              },
              ...(valuePropName && {
                [valuePropName]: field.value,
              }),
            })
        )}
      </div>
    </AntdForm.Item>
  );
};
export default FormItem;
