import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import type { PortalClientError } from "../../types";
import type { Content, JsonApiResponse } from "../types";
import { ArticlePayload } from "./types";

const useUpdateArticle = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Content>,
    PortalClientError,
    { id: string; data: ArticlePayload }
  >
) => {
  const client = useClient();

  return useMutation(({ id, data }) => client.put(`/contents/${id}`, data), {
    ...queryOptions,
  });
};

export default useUpdateArticle;
