import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Content } from "../types";

const useArticle = <TData extends {} = JsonApiResponse<Content>>(
  id?: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Content>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Content>, PortalClientError, TData>(
    ["contents", id],
    () => client.get(`/contents/${id}`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};
export default useArticle;
